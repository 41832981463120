import { render, staticRenderFns } from "./ProposalCustomGrid.vue?vue&type=template&id=61bd7b33&scoped=true"
import script from "./ProposalCustomGrid.vue?vue&type=script&lang=js"
export * from "./ProposalCustomGrid.vue?vue&type=script&lang=js"
import style0 from "./ProposalCustomGrid.vue?vue&type=style&index=0&id=61bd7b33&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61bd7b33",
  null
  
)

export default component.exports